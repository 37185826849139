<template>
  <div>
    <!-- <Header :url="require('@/assets/images/museum.png')" /> -->
    <Header :banner="bannerList" />
    <Menu :menuList="menuList" @fun="getMeun"></Menu>
    <div class="container" v-if="selMenu == 'a1' || selMenu == 'a2' || selMenu == '商店'">
      <div class="row" style="margin-bottom: 30px;">
        <div class="col">
          <p class="defaultWord" v-html="dtjsList.content"></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- <div class="proBanner" @click="$util.goLink(dtggList.link)">
            <img :src="dtggList.img" alt="">
          </div> -->
          <swiper :imgs="dtggList"></swiper>
        </div>
      </div>
      
      <div class="row">
        <div class="col mb40 col-lg-3 col-sm-12" v-for="item in cswzList" :key="item.id">
          <div class="proOne active flexCenter cursor" @click="$util.goLink(dtggList.link)">
            <img :src="item.img" alt="" class="img-fluid">
            <div class="proTitle textLeft">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col dyMore flexCenter cursor mb40" @click="lookMore">
          查看更多
        </div>
      </div>
      <div class="row" v-if="screenWidth > 600">
        <div class="col flexBtn column">
          <div class="row" v-for="item in bwglocaList" :key="item.id">
            <div class="col">
              <div class="muBox flexBtn">
                <div class="col muLeft flexCenter column">
                  <img :src="item.img" alt="">
                  <p class="defaultWord mt20">{{item.name}}</p>
                </div>
                <div class="col muRight">
                  <p class="defaultWord mb20">{{item.content}}</p>
                  <p class="defaultWord mt30">地址: {{item.location}}</p>
                  <p class="defaultWord">电话：{{item.phone}}</p>
                  <p class="defaultWord">邮箱：{{item.email}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col" @click="goMap()">
          <img src="@/assets/images/map.png" alt="" class="cursor img-fluid">
        </div>
      </div>
      <div class="row" v-else>
        <div class="col" v-for="item in bwglocaList" :key="item.id">
          <div class="muBox mb30">
            <div class="muLeft flexCenter column">
              <img :src="item.img" alt="">
              <p class="defaultWord mt20">{{item.name}}</p>
            </div>
            <div class="col muRight">
              <p class="defaultWord mb20">{{item.content}}</p>
              <p class="defaultWord mt30">地址: {{item.location}}</p>
              <p class="defaultWord">电话：{{item.phone}}</p>
              <p class="defaultWord">邮箱：i{{item.email}}</p>
            </div>
          </div>
        </div>
        <div class="col-12" @click="goMap()">
          <img src="@/assets/images/map.png" alt="" class="cursor img-fluid">
        </div>
      </div>

    </div>
    <div class="container" v-else-if="selMenu == 'a3'">
      <div class="row flexEnd">
        <div class="col-md-3 flexStart">
          <input type="text" class="form-control" v-model="kw" placeholder="关键词查询">
          <img src="@/assets/images/search.png" alt="" class="cursor" style="width:35px;margin-left: 10px;">
        </div>
      </div>
      <!-- <div class="row">
        <div class="col"></div>
      </div> -->
      <ul class="list-group list-group-flush mt40">
        <li class="list-group-item active">
          <div class="row">
            <div class="col-3 textLeft">编号</div>
            <div class="col-7 textLeft">名称</div>
            <div class="col-2 textLeft">年代</div>
          </div>
        </li>
        <li class="list-group-item" v-for="item in cswzList" :key="item.id">
          <div class="row">
            <div class="col-3 textLeft defaultWord text-truncate">{{item.bh}}</div>
            <div class="col-7 textLeft defaultWord text-truncate flexStart cursor" @click="goDetail(item)">
             {{item.title}}
              <img src="@/assets/images/sea.png" alt="" style="width:20px;margin-left: 10px;">
            </div>
            <div class="col-1 textLeft defaultWord">{{item.nd}}</div>
          </div>
        </li>
      </ul>
      <div class="row">
        <div class="col flexCenter">
          <el-pagination :current-page="kw_page" :page-size="list_rows" :total="total" background layout="prev,pager,next"
            style="text-align: center" @size-change="handleSizeChange"
            @current-change="currentChange" class="mt30">
          </el-pagination>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="proBanner"></div>
        </div>
      </div>
    </div>
    <div class="container" v-if="selMenu == 'a3'">
      <div class="row">
        <div class="col">
          <swiper :imgs="bwgdbgg"></swiper>
          <!-- <img :src="bwgdbgg.img" alt="" style="width:100%"> -->
        </div>
      </div>
    </div>
    
    <div class="showWechat flexCenter" v-if="showWechat" @click="showClick">
      <img src="@/assets/images/wechat.jpg" alt="">
    </div>
    <Footer :show="false"></Footer>
  </div>
</template>

<script>
import swiper from '@/components/swiperCom.vue';
export default {
  name: "about",
   components: {
    swiper
  },
  data() {
    return {
      dtjsList:{},
      dtggList:{},
      bwgdbgg:{},
      cswzList:[],
      bwglocaList:[],
      bannerList:[],
      showWechat:false,
      page: 1,
      limit: 15,
      type: 0,
      last_page: 0,
      isMove: false,
      selMenu: 'a2',
      screenWidth: document.body.clientWidth,
      kw_page:1,
      list_rows:20,
      total:300,
      kw:'',
      menuList: [
        {
          id: 'a1',
          type: 0,
          name: '动态',
        }, {
          id: 'a2',
          name: '展览',
        }, 
        // {
        //   id: 'a3',
        //   name: '藏品',
        // }, 
        {
          id: '商店',
          name: '商店',
        },
      ]
    }
  },
  methods: {
    //banner
    get_two_banner() {
      this.$get('/api/get_two_banner', { type: 5 }).then(res => {
        this.bannerList = res.data.data;
      }).catch(err => {

      })
    },
    goMap(){
      window.open('https://map.baidu.com/')
    },
    //博物馆动态顶部介绍
    get_index_dtjs() {
      this.$get('/api/get_index_dtjs', {}).then(res => {
        res.data[0].content = res.data[0].content.replace(/\n/g, '<br>').replace(/<br><br>/g, '<br>');
        this.dtjsList = res.data[0];
      }).catch(err => {

      })
    },
    //广告位
    get_index_dtgg() {
      this.$get('/api/get_index_dtgg', {}).then(res => {
        this.dtggList = res.data;
      }).catch(err => {

      })
      this.$get('/api/get_index_bwgdbgg', {}).then(res => {
        this.bwgdbgg = res.data;
      }).catch(err => {

      })
    },
    //获取文章列表
    get_index_bwgwz() {
      this.$get('/api/get_index_bwgwz', { page: this.page, limit: this.limit, type: this.type }).then(res => {
        if (this.page == 1) {
          this.cswzList = res.data.data;
        } else {
          this.cswzList = [...this.cswzList, ...res.data.data];
        }
        this.last_page = res.data.last_page;
      }).catch(err => {

      })
    },
    //点击查看更多
    lookMore() {
      if (this.page == this.last_page) {
        this.$message.warning('已经到底了')
      } else {
        this.page++;
        this.get_index_bwgwz()
      }
    },
    //广告位
    get_index_bwgloca() {
      this.$get('/api/get_index_bwgloca', {}).then(res => {
        this.bwglocaList = res.data;
      }).catch(err => {

      })
    },
    getMeun(e) {
      this.selMenu = e;
      if(e=='a3'){
        this.get_index_cplist();
      } else if (e == '商店'){
        this.showWechat=true;
        this.$forceUpdate();
      }
    },
    showClick(){
      this.showWechat = false; 
      this.$forceUpdate()
    },
    //获取藏品列表
    get_index_cplist() {
      this.$get('/api/get_index_cplist', { page: this.kw_page, limit: this.list_rows, kw: this.kw }).then(res => {
        this.cswzList = res.data.data;
        this.total=res.data.total;
      }).catch(err => {

      })
    },

    //点击跳转详情
    goDetail(item){
      // this.$router.push({
      //   path: '/detail',
      //   query: { id: item.id }
      // })
      this.$router.push({ path: '/detail/'+item.id});
    },
    //上一页  下一页
    handleSizeChange(e){
      this.kw_page = e;
      this.get_index_cplist();
    },
    //页数改变
    currentChange(e){
      this.kw_page =e;
      this.get_index_cplist();
    }
  },
  mounted() {
    this.get_index_dtjs();
    this.get_index_dtgg();
    this.get_index_bwgwz();
    this.get_index_bwgloca();
    this.get_two_banner();
    
  }
}
</script>

<style scoped lang="scss">
/deep/.el-pagination.is-background .btn-next,/deep/.el-pagination.is-background .btn-prev{
  background: #fff;
  display: flex;
  align-items: center;
}
/deep/.el-icon,/deep/.el-icon svg{
  width: 2em;
  height: 2em;
}
/deep/.el-icon.more svg{
width: 1em;
  // height: 1em;
}
/deep/.el-pagination.is-background .el-pager li{
  background: #fff;
    color: #4d4d4d;
    border: 1px solid #4d4d4d;
    border-radius: 5px;
}
/deep/.el-pagination.is-background .el-pager li.is-active{
  background: #fff078;
  color: #4d4d4d;
  border: 1px solid #4d4d4d;
  border-radius: 5px;
}
.showWechat {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba($color: #000000, $alpha: .5);

  img {
    border-radius: 50%;
  }
}
.list-group-flush .list-group-item:nth-child(2n-1){
  background: #f4f4f4;
}
.list-group-flush .list-group-item{
border-color: #fff;
}
.list-group-item.active{
  background: #afafaf!important;
  border-color: #afafaf!important;
}
.proOne {
  min-width: 260px;
  height: 300px;
  // background: #fff078;
  overflow: hidden;

  img {
    // width: 100%;
    // height: 100%;
  }

  &.active {
    position: relative;

    .proTitle {
      width: 100%;
      background: rgba(29, 29, 29, .6);
      position: absolute;
      left: 0;
      bottom: -3px;
      color: #fff;
      padding: 10px;
    }

    img {
      width: 100%;
    }

  }
}

.proBanner {
  width: 100%;
  background: #afafaf;
  margin: 2rem 0;
  img{
    width: 100%;
  }
}

.muBox {
  width: 100%;
  background: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
  min-width: 300px;

  .muLeft {

    //margin-right: 3rem;
    img {
      width: 100px;
    }
  }

  .muRight {}
}
</style>
